import { IconButton, MenuItem } from '@mui/material';

type SettingsMenuItemProps = {
  title: string;
  icon: preact.ComponentChild;
  onClick: () => void;
};

function SettingsMenuItem({ title, icon, onClick }: SettingsMenuItemProps) {
  return (
    <MenuItem onClick={onClick}>
      <IconButton aria-label={`Manage ${title}`} color="inherit" size="large">
        {icon}
      </IconButton>
      <p>{title}</p>
    </MenuItem>
  );
}

export default SettingsMenuItem;
