import { Alert } from '@mui/material';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { IntlProvider, Text } from 'preact-i18n';
import { useContext, useEffect, useState } from 'preact/hooks';

import { alertActionTypes, StoreContext } from '../../store';

/**
 * Snackbar notification handler
 */
function AlertPop() {
  const [{ localeCode, alert }, dispatch] = useContext(StoreContext);
  const [definition, setDefinition] = useState({});
  const { open, error, message } = alert;

  useEffect(() => {
    import(`../../translations/${localeCode}.json`).then((languageDefinition) => {
      setDefinition(languageDefinition.default);
    });
  }, [localeCode]);

  const handleClose = (_event: Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: alertActionTypes.HIDE_ALERT });
  };

  return (
    <div>
      {message && (
        <IntlProvider definition={definition}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert variant="filled" severity={error ? 'error' : 'success'} onClose={handleClose}>
              <Text id={message}>{message}</Text>
            </Alert>
          </Snackbar>
        </IntlProvider>
      )}
    </div>
  );
}

export default AlertPop;
