import RangePicker from '../../../components/rangePicker';
import { ColumnId, DateRangeValues } from '../referralTableColumn';

type SurgeryDateFilterProps = {
  allowPast: boolean;
  disableEdit: boolean;
  dateStartFilter?: Date;
  dateEndFilter?: Date;
  cellId: ColumnId;
  onFilterChange: (cellId: ColumnId, value: DateRangeValues) => void;
};

function SurgeryDateFilter({
  allowPast,
  disableEdit,
  dateStartFilter,
  dateEndFilter,
  cellId,
  onFilterChange,
}: SurgeryDateFilterProps) {
  return (
    <RangePicker
      disableEdit={disableEdit}
      allowPast={allowPast}
      size="small"
      dateRange={{ startDate: dateStartFilter, endDate: dateEndFilter }}
      updateDateRange={(dateRange) => onFilterChange(cellId, dateRange)}
    />
  );
}

export default SurgeryDateFilter;
