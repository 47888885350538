import { createTheme, Theme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    lighter: string;
    header: string;
  }
}

export const themeDef: Parameters<typeof createTheme>[0] = {
  mixins: {
    // Avoid using background color for header and pinned sections
    // https://mui.com/x/react-data-grid/style/#theme-header-and-pinned-sections
    MuiDataGrid: {
      pinnedBackground: '#ffffff00',
      containerBackground: '#ffffff00',
    },
  },
  components: {
    /** Changes color of Checkbox, Radio, Switch */
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#2196f3',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#2196f3',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#2196f3',
          },
        },
        track: {
          backgroundColor: '#000',
          '.Mui-checked + &': {
            backgroundColor: '#2196f3 !important',
          },
        },
      },
    },

    /** Used to shrink the referral list whitespace */
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          padding: '0.7rem !important',
        },
      },
    },

    /** Change icon button hover color */
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#FFB27F',
          },
        },
      },
    },

    /** Change outlined button border size */
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                borderWidth: '2px',
              },
            },
            {
              props: { variant: 'outlined', disabled: true },
              style: {
                // Required to avoid 'border' css property overriding 'borderWidth'
                borderWidth: '2px !important',
              },
            },
          ],
        },
      },
    },

    /** Hide tab indicator */
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },

    /** Increase tooltip font size */
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.05rem',
        },
      },
    },

    /** Change toggle button selected color */
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(33, 150, 243, 0.16)',
            color: '#2196f3',
            '&:hover': {
              backgroundColor: 'rgba(33, 150, 243, 0.23) !important',
            },
          },
        },
      },
    },

    /** Change color of table sort labels */
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#323232 !important',
          fontWeight: 700,
          fontSize: 16,
          '&:hover': {
            color: '#2196f3 !important',
          },
          '&.Mui-active': {
            color: '#2196f3 !important',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#091550',
    },
    secondary: {
      main: '#2196f3',
    },
    error: {
      main: '#ff5173',
    },
    success: {
      main: '#19867b',
    },
    background: {
      default: '#f2f2f2',
      lighter: '#f7f7f7',
      header: '#ececec',
    },
    text: {
      secondary: '#303030',
    },
  },
  typography: {
    h1: {
      color: '#323232',
      fontWeight: 'bold' as const,
      fontSize: '40px',
    },
    h2: {
      color: '#e95d98',
      fontWeight: 400,
      fontSize: '2.5rem',
      marginBottom: '1rem',
      letterSpacing: '0em',
    },
    h3: {
      fontSize: 22,
      color: '#323232',
      fontWeight: 'bold' as const,
      lineHeight: 3.5,
    },
    h4: {
      marginTop: '0rem',
      fontSize: '1rem',
      color: '#e95d98',
      fontWeight: 200,
    },
    h5: {
      fontSize: '0.9rem',
      color: '#19857b',
      fontWeight: 200,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.29,
    },
    subtitle1: {
      fontSize: 16,
      color: '#323232',
      fontWeight: 'bold' as const,
    },
    subtitle2: {
      color: '#565656',
      fontSize: 14,
      fontWeight: 'bold' as const,
      lineHeight: 1.29,
    },
    caption: {
      fontSize: '0.75rem',
    },
    button: {
      textTransform: 'none' as const,
    },
    fontFamily: ['Google Sans', 'Sans-Serif'].join(','),
  },
};

const theme = createTheme(themeDef);
export const arabicTheme = createTheme({
  direction: 'rtl',
  ...themeDef,
  typography: {
    ...themeDef.typography,
    fontSize: 16,
  },
});

export default theme;
