import { Checkbox, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { EndoscopyScreenFilterText, ScreenFilterText } from '../../lib/riskDisplayUtils';
import { assertUnreachable, PartialRecord } from '../../lib/tsUtils';
import Feature from '../../lib/types/feature';
import { ReferralListItem } from '../../lib/types/referralListItem';
import RiskLevel, { RiskLevelType } from '../../lib/types/riskLevel';
import Risk, { RiskType } from '../../lib/types/riskType';
import { formatOptionalDate, index } from '../../lib/utils';
import usePrehabRecommendationService from '../../services/usePrehabRecommendationService';
import sharedConstants from '../../shared-constants';
import {
  DateWaitlistedStatusCell,
  ReferralAvatar,
  ReferralStatusCell,
  SurgicalUnitCell,
} from './referralTableCells';
import { ColumnId, tableHeaders } from './referralTableColumn';
import PrehabBadge from './riskBadges/prehabBadge';
import ReferralRiskLevelBadge from './riskBadges/referralRiskLevelBadge';
import RiskBadge from './riskBadges/riskBadge';
import SuitabilityScreenBadge from './riskBadges/suitabilityScreenBadge';
import { getTabOptions, TabOptionKey } from './tabSelector';
import { ReferralTableItem } from './types';
import useActiveReferralListType from './useActiveReferralListType';

const useStyles = makeStyles((theme) => ({
  asaGreen: {
    '& td:first-child': {
      borderLeft: `solid 9px ${sharedConstants.trafficLight.green}`,
    },
  },
  asaOrange: {
    '& td:first-child': {
      borderLeft: `solid 9px ${sharedConstants.trafficLight.orange}`,
    },
  },
  asaRed: {
    '& td:first-child': {
      borderLeft: `solid 9px ${sharedConstants.trafficLight.red}`,
    },
  },
  asaDefault: {
    '& td:first-child': {
      borderLeft: 'solid 9px transparent',
    },
  },
  complexityMetsRiskInconclusive: {
    '& td:first-child': {
      borderLeft: 'solid 9px #9f9f9f',
    },
  },
  rightAlignColumn: {
    paddingRight: `${theme.spacing(3)} !important`,
  },
  centerAlignColumn: {
    textAlign: 'center',
  },
}));

type ReferralTableBodyProps = {
  columns: ColumnId[];
  referrals: ReferralTableItem[];
  currentTab: TabOptionKey;
  onRowChecked: (referral: ReferralTableItem, checked: boolean) => void;
  onRowClicked: (referral: ReferralTableItem) => void;
  featureList: Feature[];
};

function ReferralTableBody({
  columns,
  referrals,
  currentTab,
  onRowChecked,
  onRowClicked,
  featureList,
}: ReferralTableBodyProps) {
  const classes = useStyles();
  const activeListType = useActiveReferralListType();
  const { getPrehabRecommendations } = usePrehabRecommendationService();

  const [visibleColumns, setVisibleColumns] = useState<ColumnId[]>([]);
  const [prehabRecommendations, setPrehabRecommendations] = useState<
    PartialRecord<RiskType, string[]>
  >({});

  useEffect(() => {
    if (featureList.includes(Feature.PREHABILITATION)) {
      getPrehabRecommendations().then(setPrehabRecommendations);
    }
  }, [featureList, getPrehabRecommendations]);

  useEffect(() => {
    const tabOptions = getTabOptions(activeListType);
    setVisibleColumns(tabOptions[currentTab].columns.filter((c) => columns.includes(c)));
  }, [currentTab, columns, activeListType]);

  const getAsaColor = (asaScore: number) => {
    if (asaScore === 1) {
      return classes.asaGreen;
    }
    if (asaScore === 2) {
      return classes.asaOrange;
    }
    if (asaScore >= 3) {
      return classes.asaRed;
    }
    return classes.asaDefault;
  };

  const getComplexityMetsRiskColor = (complexityMetsRiskLevel: RiskLevelType | null) => {
    if (!complexityMetsRiskLevel) {
      // No risk score, don't show border color
      return classes.asaDefault;
    }

    if (complexityMetsRiskLevel === RiskLevel.Low) {
      return classes.asaGreen;
    }
    if (complexityMetsRiskLevel === RiskLevel.Medium) {
      return classes.asaOrange;
    }
    if (complexityMetsRiskLevel === RiskLevel.High) {
      return classes.asaRed;
    }

    // Inconclusive score, show grey border
    return classes.complexityMetsRiskInconclusive;
  };

  const getRowColor = (referral: ReferralListItem) => {
    if (columns.includes('asaScore')) {
      const asa = referral.risks.find((r) => r.riskType === Risk.ASA);
      if (asa?.riskScore.value) {
        return getAsaColor(asa.riskScore.value);
      }
    }
    const mets = referral.risks.find((r) => r.riskType === Risk.METS);
    if (mets) {
      return getComplexityMetsRiskColor(mets.riskLevel);
    }
    return undefined;
  };

  const handleCheckboxClicked = (e: Event) => {
    // Prevent the row onClick event firing when selecting a checkbox
    e.stopPropagation();
  };

  return (
    <>
      {referrals.map((referral) => {
        const risks = index(referral.risks, 'riskType');

        return (
          <TableRow
            hover
            key={referral.gpReferralUuid}
            onClick={() => onRowClicked(referral)}
            className={getRowColor(referral)}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={referral.isSelected}
                onClick={handleCheckboxClicked}
                onChange={(_: Event, checked: boolean) => onRowChecked(referral, checked)}
              />
            </TableCell>

            {tableHeaders
              .filter((h) => visibleColumns.includes(h.id))
              .map((column) => {
                switch (column.id) {
                  case 'name':
                    return (
                      <TableCell>
                        <ReferralAvatar
                          gpReferralUuid={referral.gpReferralUuid}
                          currentState={referral.currentState}
                          patient={referral.patient}
                          medWarningsPresent={referral.medWarningsPresent}
                          locationWarningsPresent={referral.locationWarningsPresent}
                          surgeryLocationName={referral.surgeryLocationName}
                          patientEmailState={referral.patientEmailState}
                          sendReminders={referral.sendReminders}
                        />
                      </TableCell>
                    );
                  case 'ur':
                    return (
                      <TableCell align="right" className={classes.rightAlignColumn}>
                        {referral.patient.patientUR}
                      </TableCell>
                    );
                  case 'surgicalUnitName':
                    return (
                      <TableCell>
                        <SurgicalUnitCell
                          surgicalUnitName={referral.surgicalUnitName}
                          procedureName={referral.procedureName}
                        />
                      </TableCell>
                    );

                  case 'procedureName':
                    return <TableCell>{referral.procedureName}</TableCell>;
                  case 'state':
                    return (
                      <TableCell>
                        <ReferralStatusCell
                          state={referral.currentState}
                          lastTransitionDate={referral.lastTransitionDate}
                        />
                      </TableCell>
                    );

                  case 'surgeryDate':
                    return <TableCell>{formatOptionalDate(referral.surgeryDate)}</TableCell>;

                  case 'triageCategory':
                    return (
                      <TableCell align="right" className={classes.rightAlignColumn}>
                        {referral.triageCategory}
                      </TableCell>
                    );

                  case 'followUp': {
                    const followUp = risks[Risk.FollowUp];
                    return (
                      <TableCell>
                        {followUp?.riskLevel === RiskLevel.High && (
                          <RiskBadge
                            riskLevel={followUp.riskLevel}
                            suggestion={followUp.suggestion}
                            suggestionTitle="Assessment"
                            textMapping={ScreenFilterText}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  case 'postOp': {
                    const postOp = risks[Risk.PostOp];
                    return (
                      <TableCell>
                        {postOp && (
                          <RiskBadge
                            riskLevel={postOp.riskLevel}
                            suggestion={postOp.suggestion}
                            assessment={postOp.assessment}
                            suggestionTitle="Post-op"
                            textMapping={ScreenFilterText}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  case 'prehab': {
                    const prehab = risks[Risk.Prehab];
                    return (
                      <TableCell>
                        {prehab && prehab.riskLevel === RiskLevel.High && (
                          <PrehabBadge
                            prehabRisk={prehab}
                            prehabRecommendations={prehabRecommendations}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  case 'waitlistDate':
                    return (
                      <TableCell>
                        <DateWaitlistedStatusCell
                          createdDate={referral.waitlistDate}
                          triageCategory={referral.triageCategory}
                        />
                      </TableCell>
                    );

                  case 'rehab': {
                    const rehab = risks[Risk.Rehab];
                    return (
                      <TableCell>
                        {/* Only show actionable rehab recommendations */}
                        {rehab && rehab?.riskLevel === RiskLevel.High && (
                          <RiskBadge
                            riskLevel={rehab.riskLevel}
                            suggestion={rehab.suggestion}
                            assessment={rehab.assessment}
                            suggestionTitle="Rehabilitation"
                            textMapping={ScreenFilterText}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  case 'complexityMetsRisk': {
                    const metsRisk = risks[Risk.ComplexityMetsRisk];
                    return (
                      <TableCell>
                        {metsRisk && (
                          <RiskBadge
                            riskLevel={metsRisk.riskLevel}
                            suggestion={metsRisk.suggestion}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  case 'asaScore':
                    return (
                      <TableCell align="right" className={classes.rightAlignColumn}>
                        {risks[Risk.ASA]?.riskScore.value}
                      </TableCell>
                    );

                  case 'riskLevel': {
                    return (
                      <TableCell align="center" className={classes.centerAlignColumn}>
                        <ReferralRiskLevelBadge risks={referral.risks} />
                      </TableCell>
                    );
                  }

                  case 'privateSuitable': {
                    const privateRisk = risks[Risk.PrivateSuitable];
                    return (
                      <TableCell>
                        {privateRisk && <SuitabilityScreenBadge risk={privateRisk} />}
                      </TableCell>
                    );
                  }

                  case 'dayCase': {
                    const dayCase = risks[Risk.DayCase];
                    return (
                      <TableCell>{dayCase && <SuitabilityScreenBadge risk={dayCase} />}</TableCell>
                    );
                  }

                  case 'endoscopySuitable': {
                    const endoscopyScreen = risks[Risk.Endoscopy];
                    return (
                      <TableCell>
                        {endoscopyScreen && (
                          <SuitabilityScreenBadge
                            risk={endoscopyScreen}
                            textMappingOverride={EndoscopyScreenFilterText}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  default:
                    return assertUnreachable(column.id);
                }
              })}
          </TableRow>
        );
      })}
    </>
  );
}

export default ReferralTableBody;
