import { Clear } from '@mui/icons-material';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import moment from 'moment-timezone';
import { useState } from 'preact/hooks';
import { useEffect } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formatDate } from '../lib/utils';

type DateOnlyRange = Pick<Range, 'startDate' | 'endDate'>;

type RangePickerProps = {
  updateDateRange: (dateRange: DateOnlyRange) => void;
  dateRange: Range;
  size?: 'small' | 'medium';

  /**
   * Allow past dates to be selected.
   * If false, the minimum date will be today, even on date clear.
   * @default true
   */
  allowPast?: boolean;

  /**
   * Disables editing the date range.
   * @default false
   */
  disableEdit?: boolean;
};

const formatDateRange = (dateRange: DateOnlyRange) => {
  if (!dateRange.startDate && !dateRange.endDate) {
    return '';
  }

  const startDate = formatDate(dateRange.startDate, 'D MMM YYYY') ?? '';
  const endDate = formatDate(dateRange.endDate, 'D MMM YYYY') ?? '';
  return `${startDate} - ${endDate}`;
};

function RangePicker({
  dateRange,
  updateDateRange,
  size,
  allowPast = true,
  disableEdit = false,
}: RangePickerProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [localDateRange, setLocalDateRange] = useState<DateOnlyRange>({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });
  const [mouseHover, setMouseHover] = useState(false);

  useEffect(() => {
    setLocalDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  }, [dateRange]);

  const clearDateFilter = () => {
    const clearVal = allowPast ? {} : { startDate: new Date() };
    setLocalDateRange(clearVal);
    updateDateRange(clearVal);
  };

  /** Restore original date range and close dialog */
  const cancel = () => {
    setLocalDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });

    setDialogOpen(false);
  };

  const toggle = () => {
    // if about to close, then update dateRange
    // this must happen before setOpen, because state setters are asynchronous
    if (dialogOpen) {
      const { endDate } = localDateRange;
      const startDate = allowPast
        ? localDateRange.startDate
        : localDateRange.startDate ?? new Date();
      updateDateRange({ startDate, endDate });
    }

    setDialogOpen(!dialogOpen);
  };

  const onDateRangeChange = (range: DateOnlyRange | undefined) => {
    // If we have the end date set then set it to the end of the day
    const newRange = {
      startDate: range?.startDate,
      endDate:
        range?.endDate && moment(range.endDate).set({ hour: 23, minute: 59, second: 59 }).toDate(),
    };

    if (allowPast && !range?.startDate) {
      setLocalDateRange({ ...newRange, startDate: new Date() });
    } else {
      setLocalDateRange(newRange);
    }
  };

  const hasDate = !!(dateRange?.startDate && (allowPast || dateRange?.endDate));

  const showClearBtn = hasDate && mouseHover && !disableEdit;

  return (
    <>
      <TextField
        fullWidth
        size={size}
        disabled={disableEdit}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {showClearBtn && (
                  <IconButton
                    size="small"
                    sx={{ mr: '4px' }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      clearDateFilter();
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                )}
                {!disableEdit && (
                  <IconButton size="small" sx={{ p: 0, mr: '-4px' }}>
                    <InsertInvitationIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
        onClick={() => {
          if (!disableEdit) {
            toggle();
          }
        }}
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        onKeyDown={(e: KeyboardEvent) => {
          // Disallow typing but allow tab navigation
          if (e.code !== 'Tab') {
            e.preventDefault();
          }
        }}
        value={formatDateRange(localDateRange)}
        margin="dense"
      />
      <Dialog open={dialogOpen} onClose={toggle}>
        <DateRangePicker
          minDate={allowPast ? undefined : new Date()}
          onChange={(item) => onDateRangeChange(item.selection)}
          ranges={[{ ...localDateRange, key: 'selection' }]}
          moveRangeOnFirstSelection={false}
          direction="horizontal"
        />

        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>

          <Button onClick={toggle} autoFocus variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RangePicker;
