import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { jssPreset, StylesProvider } from '@mui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useLocation } from 'react-router';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { localeActionTypes, StoreContext } from '../store';
import theme, { arabicTheme } from '../style/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type Props = {
  children: JSX.Element | JSX.Element[];
};

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
  key: 'mui',
});

function ThemeProvider({ children }: Props) {
  const location = useLocation();
  const [direction, setDirection] = useState<'rtl' | 'ltr'>('ltr');
  const [state, dispatch] = useContext(StoreContext);
  const { localeCode } = state;

  // RTL is only supported for questionnaire
  const isQuestionnaireRoute = location.pathname?.indexOf('/healthquestionnaire') === 0;

  useEffect(() => {
    if (!isQuestionnaireRoute && localeCode !== 'en') {
      // Swap to english locale if we aren't in a questionnaire
      dispatch({ type: localeActionTypes.SET_LOCALE, payload: 'en' });
    }
    if (localeCode === 'ar' && isQuestionnaireRoute) {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [dispatch, isQuestionnaireRoute, localeCode]);

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
  }, [direction]);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={direction === 'ltr' ? theme : arabicTheme}>
        <StylesProvider jss={jss}>
          <CacheProvider value={direction === 'ltr' ? cacheLtr : cacheRtl}>
            <CssBaseline />
            {children}
          </CacheProvider>
        </StylesProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
