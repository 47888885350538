import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { formatOptionalDate } from '../../../lib/utils';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const CAT_DAYS_BUFFER = 10;
const CAT_DAYS_MAP: Record<number, number> = { 1: 30, 2: 90, 3: 200 };
type CatDaysMapType = keyof typeof CAT_DAYS_MAP;

type Props = {
  readonly createdDate: Date;
  readonly triageCategory: CatDaysMapType | null;
};

function DateWaitlistedStatusCell({ createdDate, triageCategory }: Props) {
  const curDate = dayjs().startOf('day');
  const createdAtDate = dayjs(createdDate).startOf('day');
  const daysSinceCreated = curDate.diff(createdAtDate, 'day');

  const daysThreshold =
    triageCategory != null ? CAT_DAYS_MAP[triageCategory] - CAT_DAYS_BUFFER : null;

  return (
    <>
      {formatOptionalDate(createdDate)}
      <Typography color={daysThreshold && daysSinceCreated >= daysThreshold ? 'error' : 'initial'}>
        ({daysSinceCreated} days ago)
      </Typography>
    </>
  );
}

export default DateWaitlistedStatusCell;
