import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'preact/hooks';
import { ColumnId } from '../referralTableColumn';

type CreatedDateFilterProps = Readonly<{
  value: Date | null;
  cellId: ColumnId;
  onFilterChange: (cellId: ColumnId, value?: Date) => void;
  disableFuture: boolean;
}>;

function CreatedDateFilter({
  cellId,
  value,
  onFilterChange,
  disableFuture,
}: CreatedDateFilterProps) {
  // Initial value should come from filter state
  const [selectedCreatedDate, setCreatedDate] = useState<Date | null>(value);

  // Updated created date when user selects new date
  const handleCreatedAtChange = (createdDate: Dayjs | null) => {
    const selectedDate = createdDate?.isValid() ? createdDate.startOf('day').toDate() : undefined;
    setCreatedDate(selectedDate ?? null);
    onFilterChange(cellId, selectedDate);
  };

  useEffect(() => {
    if (value == null) {
      setCreatedDate(null);
    }
  }, [value]);

  return (
    <DatePicker<Dayjs>
      disableFuture={disableFuture}
      name={`${cellId}Filter`}
      format="DD MMM YYYY"
      value={selectedCreatedDate ? dayjs(selectedCreatedDate) : null}
      onChange={handleCreatedAtChange}
      slotProps={{
        textField: { size: 'small' },
        field: { clearable: true },
      }}
    />
  );
}

export default CreatedDateFilter;
