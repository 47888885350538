import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { filterRisksForDisplay, getRiskStyle } from '../../../lib/riskDisplayUtils';
import { ReferralRisk } from '../../../lib/types/referralRisk';
import RiskLevel from '../../../lib/types/riskLevel';
import { riskTitleMap } from '../../../lib/types/riskType';
import { formatText, naturalOrder } from '../../../lib/utils';
import RiskBadge from './riskBadge';

const RISK_ORDER = [RiskLevel.Low, RiskLevel.Medium, RiskLevel.High];

type Props = {
  risks: ReferralRisk[];
};

function ReferralRiskLevelBadge({ risks }: Readonly<Props>) {
  const displayRisks = risks
    .filter(filterRisksForDisplay(false))
    .filter((risk) => risk.riskLevel !== RiskLevel.Low);

  const maxRiskLevel = displayRisks
    .map((r) => r.riskLevel)
    .reduce((a, b) => (RISK_ORDER.indexOf(a) < RISK_ORDER.indexOf(b) ? b : a), RiskLevel.Low);

  const popoverContent = useMemo(() => {
    // Don't show popover for low risk referrals
    if (displayRisks.length <= 0) {
      return null;
    }

    return (
      <>
        <Typography variant="h4">Risks</Typography>
        {displayRisks
          .sort((a, b) => naturalOrder(riskTitleMap[a.riskType], riskTitleMap[b.riskType]))
          .map((risk) => (
            <div key={risk.riskType}>
              {riskTitleMap[risk.riskType]}{' '}
              <span style={getRiskStyle(risk.riskLevel)}>{formatText(risk.riskLevel)}</span>
            </div>
          ))}
      </>
    );
  }, [displayRisks]);

  return <RiskBadge riskLevel={maxRiskLevel} popoverContent={popoverContent} />;
}

export default ReferralRiskLevelBadge;
