import { Box } from '@mui/material';
import { styled } from '@mui/styles';

const RootContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down(800 + theme.spacing(3) * 2)]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

export default RootContainer;
