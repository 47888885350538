import { Dayjs } from 'dayjs';
import he from 'he';
import moment from 'moment-timezone';
import constants from '../shared-constants';
import { filterNullUndefined } from './tsUtils';

export const isDevelopment = process.env.NODE_ENV === 'development';
const MYSQL_ZERO_DATE = '0000-00-00';

export const hasOwnProperty = (obj: Object, prop: string) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

// convert image path to data url(base64)
export function imgToDataURL(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function onload() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.onerror = reject;
  });
}

export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Allows numbers, spaces, a + prefix, dashes, and brackets (, )
 */
export const PHONE_REGEX = /^\+?[0-9\s()-]{3,}$/;

export const validateEmail = (emailAddress: string | null) => {
  if (emailAddress && emailAddress.length > 0) {
    return EMAIL_REGEX.test(emailAddress.toLowerCase());
  }
  return false;
};

export const NUM_REGEX = /^\d+(\.\d+)?$/;

export function caseInsensitiveCompare(a: string, b: string): number {
  return a.localeCompare(b, undefined, { sensitivity: 'accent' });
}

/** Parse HTML entities in string, and capitalize first letter */
export const formatText = (text: string | null | undefined) => {
  if (!text || typeof text !== 'string') {
    return text;
  }

  const decoded = he.decode(text);
  return decoded.charAt(0).toUpperCase() + decoded.slice(1);
};

/**
 * Convert HTML list to array of strings, with HTML tags removed
 */
export const splitHTMLList = (answer: string) =>
  answer.split('<li>').map((li) => li.replace(/<\/?\w+>/g, ''));

/**
 * Convert string containing HTML list to readable multiline string
 * Strips out all tags
 */
export const formatHTMLList = (answer: string) => splitHTMLList(answer).join('\n- ');

export const formatDate = (
  date: string | Date | null | undefined,
  format: string = 'DD/MM/YYYY',
) => {
  if (date == null) {
    return null;
  }

  if (typeof date === 'string') {
    if (date === MYSQL_ZERO_DATE) {
      return null;
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  } else if (typeof date !== 'object') {
    return null;
  }

  const isoDateTime = date.toISOString();
  return moment(isoDateTime).format(format);
};

export const formatDateTime = (dateTime: string | Date | null) => {
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime).toISOString();
};

export const formatDateTimeUI = (dateTime: string | Date | null) => {
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime).toLocaleString(constants.locales, {
    dateStyle: 'full',
    timeStyle: 'short',
  });
};

/**
 * This can be used for a nicely formatted date for the UI,
 * even if the date is empty or invalid
 */
export const dateString = (date: Date | string | null) => {
  const returnDate = formatDateTimeUI(date);
  return returnDate || 'TBD';
};

/**
 * Returns date and time and how many days till surgery (negative means it is in the future), with red if within 5 days
 * e.g. 21/01/2022 (-5 days), at 18:30
 */
export const formatSurgeryDateTime = (surgeryDateString: string | null | Date) => {
  let formattedSurgeryDate: JSX.Element | string = 'TBA';

  if (!surgeryDateString) {
    return formattedSurgeryDate;
  }

  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const todaysDate = new Date();

  const surgeryDate = new Date(surgeryDateString);
  // Coerce to number to avoid typescript error
  const daysBetween = Math.round((+todaysDate - +surgeryDate) / oneDay);
  const color = daysBetween <= 0 && daysBetween >= -5 ? 'red' : '';

  let daysBetweenString: string | number = daysBetween;
  if (daysBetween !== 0) {
    daysBetweenString = daysBetween > 0 ? `+${Math.abs(daysBetween)}` : `-${Math.abs(daysBetween)}`;
  }

  const surgeryTime = surgeryDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  formattedSurgeryDate = (
    <span>
      {surgeryDate.toLocaleDateString()}{' '}
      <span style={{ color }}>
        ({daysBetweenString} days), at {surgeryTime}
      </span>
    </span>
  );

  return formattedSurgeryDate;
};

export const dayJSDateTimeFormat = 'DD/MM/YYYY hh:mm A';
export const isValidDateTime = (date: string | Date) => moment(date).isValid();

export const maxDate = (dates: (Date | null | undefined)[]) => {
  const filteredDates = dates.filter(filterNullUndefined);
  if (filteredDates.length <= 0) {
    return null;
  }
  return new Date(Math.max(...filteredDates.map(Number)));
};

/**
 * Extract array of files from string
 * @param {string} files Pipe-separated list of files. Each file should be in the format 'uuid/filename'
 */
export const extractFiles = (files: string | null) => {
  const fileList = files === null ? [] : files.split('|');
  return fileList.map((file: string) => ({
    fileUuid: file.split('/')[0],
    fileName: file.split('/')[1],
  }));
};

type DateValidateType = 'birthday' | 'surgeryDate' | 'any';

export const getDateErrorHelperText = (type: DateValidateType, error: any) => {
  const dateTime = error.ref.value;
  if (dateTime === '') {
    return 'This field is required';
  }

  const nowYear = new Date().getFullYear();
  if (type === 'birthday') {
    if (dateTime.split('/')[1] > 12 || dateTime.split('/')[1] < 1) {
      return 'The month field is invalid.';
    }
    if (dateTime.split('/')[2] > nowYear || dateTime.split('/')[2] < 1900) {
      return 'The year field is invalid.';
    }
    return 'The day field is invalid.';
  }
  if (type === 'surgeryDate') {
    if (dateTime.split(/\/|\s|:/g)[1] > 12 || dateTime.split(/\/|\s|:/g)[1] < 1) {
      return 'The month field is invalid.';
    }
    if (dateTime.split(/\/|\s|:/g)[2] > nowYear + 20 || dateTime.split(/\/|\s|:/g)[2] < nowYear) {
      return 'The year field is invalid.';
    }
    if (dateTime.split(/\/|\s|:/g)[3] > 12 || dateTime.split(/\/|\s|:/g)[3] < 1) {
      return 'The hour field is invalid.';
    }
    if (dateTime.split(/\/|\s|:/g)[4] > 59) {
      return 'The minute field is invalid.';
    }
    if (dateTime.split(/\/|\s|:/g)[5] !== 'AM' && dateTime.split(/\/|\s|:/g)[5] !== 'PM') {
      return 'The am/pm field is invalid.';
    }
    return 'The day field is invalid.';
  }
  return 'This field is required';
};

export const dateValidate = (
  date: string | Date | null,
  type: DateValidateType,
  required = false,
) => {
  if (!date && !required) {
    return true;
  }
  if (!date) {
    return false;
  }

  const dateVal = new Date(date);
  if (dateVal.toString() === 'Invalid Date') {
    return false;
  }

  const now = new Date();
  const year = dateVal.getFullYear();

  if (type === 'any' && year < 1900) {
    return false;
  }
  if (type === 'birthday' && (year < 1900 || dateVal > now)) {
    return false;
  }
  if (type === 'surgeryDate' && (year > now.getFullYear() + 20 || dateVal < now)) {
    return false;
  }
  return true;
};

export const truncate = (text: string, limit = 250) =>
  text.length > limit ? `${text.substring(0, limit)}... ` : text;

export const formatOptionalDate = (date: moment.MomentInput) => {
  if (!date) {
    return 'TBA';
  }
  const formattedDate = moment(date).format('D MMM YYYY');
  if (!formattedDate || formattedDate.toString() === 'Invalid date') {
    return 'TBA';
  }
  return formattedDate;
};

/**
 * Format number as '10m 50s'
 * @param {string} value Time, in seconds
 */
export function formatDuration(value: string) {
  const sec = parseInt(String(value), 10); // convert value to number if it's string
  let minutes: string | number = Math.floor(sec / 60); // get minutes
  let seconds: string | number = sec - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}m ${seconds}s`;
}

export const formatCamelCaseText = (text: string) => {
  if (!text) {
    return text;
  }

  return text
    .split(/(?=[A-Z])/)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1, s.length))
    .join(' ');
};

export const calculateAge = (dateOfBirth: moment.MomentInput) => {
  if (!dateOfBirth || dateOfBirth === MYSQL_ZERO_DATE) {
    return constants.wording.notSet;
  }

  return moment().diff(dateOfBirth, 'years', false);
};

export const formatToSystemTimezone = (dateTime: moment.MomentInput) =>
  moment.utc(dateTime).clone().tz(constants.systemTimezone).format('D MMM YYYY hh:mm A');

export const toDBDateFormat = (localDate: moment.MomentInput) =>
  moment(localDate).format('YYYY-MM-DD');

/**
 * Convert date to formatted string in UTC timezone for database
 *
 * Should only be used for DATE fields in the db (i.e. not DATETIME)
 */
export const toDBDateFormatUTC = (localDate: Dayjs) => localDate.utc().format('YYYY-MM-DD');

/**
 * Takes an array of objects and returns an object with the array elements
 * grouped together by the given key
 *
 * @param {Object[]} array an array of objects
 * @param key the key to group by, must be a key of the objects in the array
 * @returns {Object} an object indexed by the different keys, and the matching values as an array
 */
export function groupBy<T extends { [key: string]: any }, K extends keyof T>(
  array: T[],
  key: K,
): { [key: string]: T[] } {
  return array.reduce((result: { [key: string]: T[] }, currentValue) => {
    if (!result[currentValue[key]]) {
      // eslint-disable-next-line no-param-reassign
      result[currentValue[key]] = [];
    }
    result[currentValue[key]].push(currentValue);
    return result;
  }, {});
}

export function index<T extends { [key: string]: unknown }, K extends keyof T>(array: T[], key: K) {
  return array.reduce((previousValue: { [key: string]: T | undefined }, obj) => {
    const keyValue = obj[key];

    return {
      ...previousValue,
      [keyValue as K]: obj,
    };
  }, {});
}

/**
 * Use in place of standard `sort()` functions to ensure strings are sorted
 * correctly and sonar doesn't complain
 * @template `Array.sort(naturalOrder)`
 */
export const naturalOrder = (a: string, b: string) => a.localeCompare(b);

/**
 * Takes an array, and returns a copy with any duplicates removed.
 * Preserves array order.
 *
 * @param {any[]} arr Input array
 * @returns {any[]} Array without any duplicates
 */
export function uniqueArray<T>(arr: T[]) {
  const set = new Set(arr);
  return Array.from(set.values());
}

/**
 * Remove any null fields from an object
 *
 * Non-recursive, will only remove top-level null attributes
 */
export const removeNullFields = (obj: Object) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const subtractDays = (date: string | Date | null, days: number | null) => {
  if (typeof days === 'undefined' || days === null || !date) {
    return null;
  }
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export const safeJsonParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};
