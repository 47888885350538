import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import ContactInfoNoEmailBadge from '../../../../iconComponents/contactInfoNoEmailBadge';
import useStyles from './styles';

type ContactPrefIconProps = {
  title: string;
};

function ContactInfoAlertIcon({ title }: Readonly<ContactPrefIconProps>) {
  const classes = useStyles();

  return (
    <Tooltip title={title} arrow>
      <span>
        <ContactInfoNoEmailBadge
          alt="Contact info"
          className={clsx(classes.warningIcon, classes.referralIcon)}
        />
      </span>
    </Tooltip>
  );
}

export default ContactInfoAlertIcon;
