import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/MailTwoTone';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Checkbox, IconButton, TableCell, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useUserRole } from '../../hooks';
import {
  EndoscopyScreenFilterText,
  RiskLevelFilterText,
  ScreenFilterText,
  SuitabilityFilterText,
} from '../../lib/riskDisplayUtils';
import { assertUnreachable } from '../../lib/tsUtils';
import Feature from '../../lib/types/feature';
import { ReferralStatus } from '../../lib/types/referralStatus';
import { RiskLevelType } from '../../lib/types/riskLevel';
import { SurgicalUnit } from '../../lib/types/surgicalUnit';
import { ColumnId, ScreenType, tableHeaders } from './referralTableColumn';
import CreatedDateFilter from './referralTableFilters/createdDateFilter';
import NumberSelectFilter from './referralTableFilters/numberSelectFilter';
import OptionsFilter from './referralTableFilters/optionsFilter';
import SpecialtyFilter from './referralTableFilters/specialtyFilter';
import StatusFilter from './referralTableFilters/statusFilter';
import SurgeryDateFilter from './referralTableFilters/surgeryDateFilter';
import TextSearch from './referralTableFilters/textFilter';
import { getTabOptions, TabOptionKey } from './tabSelector';
import { FilterOptions, SortOption } from './types';
import useActiveReferralListType from './useActiveReferralListType';

const useStyles = makeStyles((theme) => ({
  tableLabelRow: {
    '& th': {
      paddingBottom: '0px !important',
      border: 'none',
    },
  },
  tableFilterRow: {
    '& th': {
      paddingTop: '0px !important',
    },
    '& #stateFilterId': {
      maxWidth: '400px',
    },
  },
  tableColCheckbox: {
    width: theme.spacing(1),
  },
}));

type Props = {
  columns: ColumnId[];
  displayedRowCount: number;
  selectedRowCount: number;
  onCheckAllChange: (checked: boolean) => void;
  sortOption: SortOption;
  onColumnSortChange: (sortOption: SortOption) => void;
  filterOptions: Partial<FilterOptions>;
  onFilterChange: (column: ColumnId, filterOptions: Partial<FilterOptions>) => void;
  currentTab: TabOptionKey;
  onAssignStaffClick?: () => void;
  onDeleteClick: () => void;
  onSendHQClick: () => void;
  surgicalUnits: SurgicalUnit[];
  featureList: Feature[];
};

function ReferralTableHead(props: Props) {
  const {
    columns,
    displayedRowCount,
    selectedRowCount,
    onCheckAllChange,
    sortOption,
    onColumnSortChange,
    filterOptions,
    onFilterChange,
    currentTab,
    onAssignStaffClick,
    onDeleteClick,
    onSendHQClick,
    surgicalUnits,
    featureList,
  } = props;
  const { orderBy, order } = sortOption;
  const classes = useStyles();
  const { isUserNurse } = useUserRole();
  const activeListType = useActiveReferralListType();
  const [visibleColumns, setVisibleColumns] = useState<ColumnId[]>([]);

  useEffect(() => {
    const tabOptions = getTabOptions(activeListType);
    setVisibleColumns(tabOptions[currentTab].columns.filter((c) => columns.includes(c)));
  }, [currentTab, columns, activeListType]);

  const sortHandler = (property: ColumnId) => (_: Event) => {
    if (property === orderBy) {
      if (order === 'asc') {
        return onColumnSortChange({ orderBy: property, order: 'desc' });
      }
    }
    return onColumnSortChange({ orderBy: property, order: 'asc' });
  };

  const handleFilterChange = (cellId: ColumnId, value?: any) => {
    onFilterChange(cellId, { ...filterOptions, [cellId]: value });
  };

  const handleStatusChange = (selectedStates: ReferralStatus[]) => {
    onFilterChange('state', { ...filterOptions, state: selectedStates });
  };

  const renderColumnFilter = (cellId: ColumnId) => {
    switch (cellId) {
      case 'name':
      case 'ur':
      case 'procedureName':
        return (
          <TextSearch
            cellId={cellId}
            value={filterOptions[cellId] ?? ''}
            onFilterChange={handleFilterChange}
          />
        );
      case 'surgicalUnitName':
        return (
          <SpecialtyFilter
            cellId={cellId}
            value={filterOptions[cellId]}
            onFilterChange={handleFilterChange}
            surgicalUnits={surgicalUnits}
          />
        );
      case 'state':
        return (
          <StatusFilter
            cellId={cellId}
            selectedFilters={filterOptions.state ?? []}
            onFilterChange={handleStatusChange}
            currentTab={currentTab}
            filterVisible={(filterKey: ReferralStatus) =>
              featureList.includes(Feature.POST_OP_HQ) ||
              (filterKey !== ReferralStatus.PostOpHQSent &&
                filterKey !== ReferralStatus.PostOpHQReceived)
            }
          />
        );
      case 'surgeryDate': {
        const filterValues = filterOptions[cellId];
        return (
          <SurgeryDateFilter
            cellId={cellId}
            dateStartFilter={filterValues?.startDate}
            dateEndFilter={filterValues?.endDate}
            onFilterChange={handleFilterChange}
            disableEdit={currentTab === TabOptionKey.PostOp}
            allowPast={currentTab === TabOptionKey.All || currentTab === TabOptionKey.AllFilters}
          />
        );
      }
      case 'triageCategory':
        return (
          <NumberSelectFilter
            cellId={cellId}
            value={filterOptions.triageCategory}
            onFilterChange={handleFilterChange}
          />
        );
      case 'asaScore':
        return (
          <NumberSelectFilter
            cellId={cellId}
            value={filterOptions.asaScore}
            onFilterChange={handleFilterChange}
          />
        );
      case 'complexityMetsRisk':
        return (
          <OptionsFilter<RiskLevelType>
            cellId={cellId}
            value={filterOptions.complexityMetsRisk}
            onFilterChange={handleFilterChange}
            filterOptions={RiskLevelFilterText}
          />
        );
      case 'riskLevel':
        return (
          <OptionsFilter<RiskLevelType>
            cellId={cellId}
            value={filterOptions.riskLevel}
            onFilterChange={handleFilterChange}
            filterOptions={RiskLevelFilterText}
          />
        );
      case 'waitlistDate':
        return (
          <CreatedDateFilter
            cellId={cellId}
            value={filterOptions.waitlistDate ?? null}
            onFilterChange={handleFilterChange}
            disableFuture
          />
        );
      case 'prehab':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.prehab}
            onFilterChange={handleFilterChange}
            filterOptions={ScreenFilterText}
          />
        );
      case 'followUp':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.followUp}
            onFilterChange={handleFilterChange}
            filterOptions={ScreenFilterText}
          />
        );
      case 'postOp':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.postOp}
            onFilterChange={handleFilterChange}
            filterOptions={ScreenFilterText}
          />
        );
      case 'rehab':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.rehab}
            onFilterChange={handleFilterChange}
            filterOptions={ScreenFilterText}
          />
        );
      case 'privateSuitable':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.privateSuitable}
            onFilterChange={handleFilterChange}
            filterOptions={SuitabilityFilterText}
          />
        );
      case 'dayCase':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.dayCase}
            onFilterChange={handleFilterChange}
            filterOptions={SuitabilityFilterText}
          />
        );
      case 'endoscopySuitable':
        return (
          <OptionsFilter<ScreenType>
            cellId={cellId}
            value={filterOptions.endoscopySuitable}
            onFilterChange={handleFilterChange}
            filterOptions={EndoscopyScreenFilterText}
          />
        );

      default:
        return assertUnreachable(cellId);
    }
  };

  return (
    <>
      <TableRow className={classes.tableLabelRow}>
        <TableCell key="checkboxCol" className={classes.tableColCheckbox}>
          <Checkbox
            indeterminate={selectedRowCount > 0 && selectedRowCount < displayedRowCount}
            checked={selectedRowCount > 0 && selectedRowCount === displayedRowCount}
            onChange={(_: Event, checked: boolean) => onCheckAllChange(checked)}
          />
        </TableCell>
        {selectedRowCount === 0 ? (
          tableHeaders.map(
            (cell) =>
              visibleColumns.includes(cell.id) && (
                <TableCell
                  key={cell.id}
                  sortDirection={orderBy === cell.id && order}
                  style={{ width: cell.width }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={order}
                    onClick={sortHandler(cell.id)}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableCell>
              ),
          )
        ) : (
          <TableCell>
            {isUserNurse && onAssignStaffClick && (
              <Tooltip title="Assign staff">
                <IconButton aria-label="assign" onClick={onAssignStaffClick} size="large">
                  <PeopleAltIcon />
                </IconButton>
              </Tooltip>
            )}
            {isUserNurse && (
              <Tooltip title="Remove referral">
                <IconButton aria-label="remove" onClick={onDeleteClick} size="large">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {currentTab === TabOptionKey.Todo && (
              <Tooltip title="Send questionnaire">
                <IconButton aria-label="Send Questionnaire" onClick={onSendHQClick} size="large">
                  <MailIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow className={classes.tableFilterRow}>
        <TableCell />
        {tableHeaders.map(
          (cell) =>
            visibleColumns.includes(cell.id) && (
              <TableCell id={`${cell.id}FilterId`} key={`${cell.id}Filter`}>
                {renderColumnFilter(cell.id)}
              </TableCell>
            ),
        )}
      </TableRow>
    </>
  );
}

export default ReferralTableHead;
