import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'preact/hooks';
import { RegisterOptions, useForm } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    '& b': {
      fontSize: '15px',
    },
  },
  textLabel: { marginRight: 10 },
  textfield: {
    margin: '0 10px 10px 0',
    '& .MuiInputBase-input': {
      padding: '0',
    },
    flexGrow: 1,
  },
}));

type ControlledItem = {
  value: any;
  label?: string;
  name: string;
  error: boolean;
};

type InputItem = Omit<ControlledItem, 'error'> & {
  unit?: string;
};

export type ControlledFieldUpdateFn = (payload: Omit<ControlledItem, 'label'>) => void;

type ControlledTextFieldProps = {
  item: InputItem;
  updateItem: ControlledFieldUpdateFn;
  editing: boolean;
  validation: RegisterOptions;
  helperText?: string;
  placeholder?: string;
};

function ControlledTextField({
  item,
  updateItem,
  editing,
  validation,
  helperText = '',
  placeholder = '',
}: ControlledTextFieldProps) {
  const { register, errors, trigger, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const classes = useStyles();
  const [editableValue, setEditableValue] = useState('');

  useEffect(() => {
    setEditableValue(item?.value);
  }, [item]);

  const handleChange = async (newVal: string) => {
    setEditableValue(newVal);
    setValue(item.name, newVal);
    await trigger(item.name);
    updateItem({ ...item, value: newVal, error: !!errors[item.name] });
  };

  register(item.name, validation);

  return (
    <div className={classes.container}>
      {item.label != null && (
        <Typography className={classes.textLabel}>
          <b> {item.unit ? `${item.label} (${item.unit})` : item.label}</b>
        </Typography>
      )}
      {editing ? (
        <TextField
          error={!!errors[item.name]}
          helperText={!!errors[item.name] && helperText}
          variant="standard"
          name={item.name}
          value={editableValue}
          onChange={(e: any) => handleChange(e.target.value)}
          className={classes.textfield}
          placeholder={placeholder || item.label}
        />
      ) : (
        <Typography name={`${item.name}-display`} sx={{ display: 'inline' }}>
          {item.value}
        </Typography>
      )}
    </div>
  );
}

export default ControlledTextField;
