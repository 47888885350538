import { MenuItem, Select } from '@mui/material';
import NoneMenuItem from '../../../components/noneMenuItem';
import { ColumnId } from '../referralTableColumn';

type NumberSelectFilterProps = {
  cellId: ColumnId;
  value?: number | null;
  onFilterChange: (cellId: ColumnId, value: number | null) => void;
};

function NumberSelectFilter({ cellId, value, onFilterChange }: NumberSelectFilterProps) {
  return (
    <Select
      size="small"
      name={`${cellId}Filter`}
      fullWidth
      sx={{ maxWidth: '200px' }}
      variant="outlined"
      value={value ?? ''}
      onChange={(e) => {
        const numOption = (e.target as HTMLInputElement).value;
        onFilterChange(cellId, !numOption ? null : +numOption);
      }}
    >
      <NoneMenuItem value={null}>None</NoneMenuItem>
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
      <MenuItem value={3}>3</MenuItem>
    </Select>
  );
}

export default NumberSelectFilter;
