import { ContactSupportOutlined } from '@mui/icons-material';
import { Paper, Popover, PopoverOrigin, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import clsx from 'clsx';
import { useContext, useState } from 'preact/hooks';
import { useLocation } from 'react-router';
import useCustomer from '../../hooks/useCustomer';
import { StoreContext } from '../../store';
import Feedback from './feedback';
import useStyles from './useStyles';

const anchorElDesk: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
} as const;

const anchorElMob: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
} as const;

const transformOriginDesk: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
} as const;

const transformOriginMob: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
} as const;

function Footer() {
  const location = useLocation();
  const url = location.pathname;

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [mobile, setMobile] = useState(false);

  const [state] = useContext(StoreContext);
  const { rtl } = state;

  const theme = useCustomer().orgSettings;
  const classes = useStyles();

  const callbackRoute = url.startsWith('/callback');
  const hqRoute = url.startsWith('/healthquestionnaire');
  const year = new Date().getFullYear();
  const versionInfo = process.env.PREACT_APP_GIT_TAG_NAME
    ? process.env.PREACT_APP_GIT_TAG_NAME
    : `(Ver: ${process.env.PREACT_APP_GIT_VERSION})`;
  const name = theme?.customName ?? 'Atidia';
  const privacyPolicyUrl = theme?.privacyPolicyUrl ?? 'https://atidia.health/privacy-policy';

  const logoSrc = '/assets/icons/Colour-Negative-onDark.png';
  const logoLink = 'https://atidia.health/';

  const handleHelpClickDesk = (event: any) => {
    setPopoverAnchor(event.currentTarget);
    setMobile(false);
  };

  const handleHelpClickMob = (event: any) => {
    setPopoverAnchor(event.currentTarget);
    setMobile(true);
  };

  const handleHelpClose = () => {
    setPopoverAnchor(null);
  };

  const popoverOpen = Boolean(popoverAnchor);

  const feedbackEmail = theme?.feedbackEmail ?? undefined;
  const feedbackPhone = theme?.feedbackPhone ?? undefined;

  // Don't render while authenticating
  if (callbackRoute) {
    return null;
  }

  return (
    <>
      <div className={classes.footer} dir="ltr">
        {/** Desktop version */}
        <div className={classes.footerDesktop}>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid size="grow">
              {hqRoute && (feedbackEmail || feedbackPhone) && (
                <Typography onClick={handleHelpClickDesk} className={classes.clickableText}>
                  <ContactSupportOutlined
                    style={{ verticalAlign: 'bottom', paddingRight: '2px' }}
                  />
                  Need help?
                </Typography>
              )}
            </Grid>
            <Grid size="auto" container spacing={2}>
              <Grid>
                &copy;{year} {name}
              </Grid>
              <Grid>
                <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </Grid>
              <Grid>{versionInfo}</Grid>
            </Grid>
            <Grid size="grow">
              <span
                className={clsx(!rtl && classes.logoContainer, rtl && classes.logoContainerRTL)}
              >
                {!rtl && <span className={classes.poweredBy}>Powered by</span>}
                <a href={logoLink} rel="noreferrer" target="_blank">
                  <img className={classes.logoImg} src={logoSrc} alt="Atidia" />
                </a>
              </span>
            </Grid>
          </Grid>
        </div>

        {/** Mobile version */}
        <div className={classes.footerMobile}>
          <Grid container sx={{ alignItems: 'flex-start' }}>
            <Grid xs={12} sm={8} className={classes.infoContainerMobile}>
              <div className={classes.infoItem}>
                &copy;{year} {name}
              </div>
              <div className={classes.infoItem}>
                <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </div>
              <div className={classes.infoItem}>{versionInfo}</div>
            </Grid>
            <Grid xs={12} sm={4}>
              {hqRoute && (feedbackEmail || feedbackPhone) && (
                <Typography onClick={handleHelpClickMob} className={classes.clickableText}>
                  Need help?
                </Typography>
              )}
              <span
                className={clsx(!rtl && classes.logoContainer, rtl && classes.logoContainerRTL)}
              >
                <a href={logoLink} rel="noreferrer" target="_blank">
                  <img className={classes.logoImg} src={logoSrc} alt="Atidia" />
                </a>
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchor}
        onClose={handleHelpClose}
        anchorOrigin={mobile ? anchorElMob : anchorElDesk}
        transformOrigin={mobile ? transformOriginMob : transformOriginDesk}
        style={{ maxWidth: mobile ? '85%' : '40%' }}
        dir="ltr"
      >
        <Paper elevation={3} className={classes.feedback}>
          <Feedback feedbackEmail={feedbackEmail} feedbackPhone={feedbackPhone} />
        </Paper>
      </Popover>
    </>
  );
}

export default Footer;
