import { MenuProps } from '@mui/material';

// These props anchor the select menu and stops it moving around. Should be fixed in Mui5
const selectMenuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
};

// eslint-disable-next-line import/prefer-default-export
export { selectMenuProps };
