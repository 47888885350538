import { Warning } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import RootContainer from './rootContainer';

const useStyles = makeStyles(() => ({
  largeIcon: {
    fontSize: '84px',
  },
  centredText: {
    textAlign: 'center' as const,
  },
  centredBox: {
    top: '50%',
    left: '50%',
    position: 'absolute' as const,
    transform: 'translate(-50%, -50%)',
  },
}));

type Props = {
  error: string;
  extraText?: string;
  showButton?: boolean;
};

/** fall-back route (handles unroutable URLs) */
export default function FullscreenErrorPage({ error, extraText, showButton = true }: Props) {
  const classes = useStyles();

  return (
    <RootContainer className={clsx(classes.centredBox, classes.centredText)}>
      <Warning className={classes.largeIcon} color="error" />
      <Typography variant="h1">{error}</Typography>
      {extraText && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h3">{extraText}</Typography>
        </Box>
      )}
      {showButton && (
        <Box sx={{ mt: 8 }}>
          <Button href="/" variant="contained" color="primary">
            Return to homepage
          </Button>
        </Box>
      )}
    </RootContainer>
  );
}
