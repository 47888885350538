import { Typography } from '@mui/material';

type FeedbackProps = {
  feedbackEmail?: string;
  feedbackPhone?: string;
};

/**
 * Text content to go into the feedback display.
 * Prioritises email over phone number if both are provided
 */
function Feedback({ feedbackEmail, feedbackPhone }: FeedbackProps) {
  const primaryContact = feedbackEmail ? (
    <a href={`mailto:${feedbackEmail}`} target="_top">
      {feedbackEmail}
    </a>
  ) : (
    feedbackPhone
  );

  return (
    <>
      <Typography variant="h3">Need help?</Typography>
      <Typography variant="h4" sx={{ color: 'textPrimary' }}>
        <p>
          If you need assistance completing this questionnaire, or you want to provide feedback on
          your experience, please contact us at {primaryContact}.
          {feedbackPhone && feedbackEmail && (
            <p>Alternatively you can call the technical support helpline on {feedbackPhone}</p>
          )}
        </p>
        <p>
          If you&apos;d like to be contacted by phone please leave a number and we will get back to
          you during business hours
        </p>
      </Typography>
    </>
  );
}

export default Feedback;
