import { MenuItem, Select } from '@mui/material';
import NoneMenuItem from '../../../components/noneMenuItem';
import { ColumnId } from '../referralTableColumn';

type FilterProps<T extends string | number | symbol> = {
  cellId: ColumnId;
  value: T | undefined | null;
  onFilterChange: (cellId: ColumnId, value?: string) => void;
  filterOptions: Record<T, string>;
};
// Filter for a column based on a set of options with a dictionary mapping option value to display label
function OptionsFilter<T extends string | number | symbol>({
  cellId,
  value,
  onFilterChange,
  filterOptions,
}: FilterProps<T>) {
  return (
    <Select
      size="small"
      name={`${cellId}Filter`}
      fullWidth
      sx={{ maxWidth: '200px' }}
      variant="outlined"
      value={value ?? ''}
      onChange={(e: any) => {
        const option = (e.target as HTMLInputElement).value;
        onFilterChange(cellId, option || undefined);
      }}
    >
      <NoneMenuItem value={null}>None</NoneMenuItem>

      {Object.entries(filterOptions).map(([key, display]) => (
        <MenuItem value={key} key={key}>
          {display}
        </MenuItem>
      ))}
    </Select>
  );
}

export default OptionsFilter;
