import { TabPanel } from '@mui/lab';
import { Divider, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/styles';
import StatusHeading from '../../components/statusHeading';
import { assertUnreachable } from '../../lib/tsUtils';
import { ClinicianStatus, clinicianStatusNames } from '../../lib/types/clinicianStatus';
import Feature from '../../lib/types/feature';
import { ReferralAlert, referralAlertNames } from '../../lib/types/referralAlerts';
import { ReferralStatus, referralStatusNames } from '../../lib/types/referralStatus';
import { TestStatus, testStatusNames } from '../../lib/types/testStatus';
import {
  alertHoverDescriptions,
  clinicianHoverDescriptions,
  testHoverDescriptions,
  workflowHoverDescriptions,
} from './hoverDescriptions';
import { FilterOptionKey, TabOptionData, TabOptionKey, tabOptionKeyNames } from './tabSelector';
import { RibbonFilterMap } from './types';

const styles = {
  alerts: {
    ml: 'auto',
    mr: 3,
  },
};

const getFilterLabel = (filterGroup: FilterOptionKey, key: string) => {
  switch (filterGroup) {
    case 'workflow':
      return referralStatusNames[key as ReferralStatus];
    case 'test':
      return testStatusNames[key as TestStatus];
    case 'clinician':
      return clinicianStatusNames[key as ClinicianStatus];
    case 'alerts':
      return referralAlertNames[key as ReferralAlert];
    default:
      return assertUnreachable(filterGroup);
  }
};

const getHoverDescription = (filterGroup: FilterOptionKey, key: string) => {
  switch (filterGroup) {
    case 'workflow':
      return workflowHoverDescriptions[key as ReferralStatus];
    case 'test':
      return testHoverDescriptions[key as TestStatus];
    case 'clinician':
      return clinicianHoverDescriptions[key as ClinicianStatus];
    case 'alerts':
      return alertHoverDescriptions[key as ReferralAlert];
    default:
      return assertUnreachable(filterGroup);
  }
};

type FilterButtonGroupProps = {
  selectedFilters: RibbonFilterMap;
  onFilterChange: (value: string, filterGroupName: FilterOptionKey) => void;
  featureList: Feature[];
  tabOptions: Record<TabOptionKey, TabOptionData>;
};

const BubbleToggleButton = styled(ToggleButton)({
  borderRadius: '22px !important',
  padding: '6px 16px',
  border: '1px solid rgba(0, 0, 0, 0.12) !important',
  margin: '0px 10px',
  opacity: 1,
});

const StyledStatusTitle = styled(StatusHeading)(({ theme }) => ({
  fontSize: '0.875rem',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(1),
  textTransform: 'capitalize',
  display: 'inline-block',
}));

function FilterButtonGroup({
  selectedFilters,
  onFilterChange,
  featureList,
  tabOptions,
}: FilterButtonGroupProps) {
  const postOpHqEnabled = featureList.includes(Feature.POST_OP_HQ);
  const endoscopyEnabled = featureList.includes(Feature.ENDOSCOPY);

  const getStyle = (filterGroup: FilterOptionKey) =>
    filterGroup === 'alerts' ? styles.alerts : undefined;

  const isSelected = (filterGroup: FilterOptionKey, key: string) =>
    selectedFilters.get(filterGroup)?.includes(key);

  const isEnabled = (filterKey: string) =>
    (postOpHqEnabled ||
      (filterKey !== ReferralStatus.PostOpHQSent &&
        filterKey !== ReferralStatus.PostOpHQReceived)) &&
    (endoscopyEnabled || filterKey !== ReferralStatus.ReadyForBowelPrep);

  return (
    <>
      {Object.entries(tabOptions).map(([key, option]) => (
        <TabPanel value={key} key={key} sx={{ p: 3 }}>
          <Grid container sx={{ alignItems: 'center' }}>
            {Object.entries(option.filter).map(([filterGroupName, filters], idx) => {
              const filterGroup = filterGroupName as FilterOptionKey;
              return (
                <>
                  {idx !== 0 && (
                    <Grid>
                      <Divider orientation="vertical" sx={{ minHeight: '54px' }} />
                    </Grid>
                  )}
                  <Grid sx={{ mb: '18px', ...getStyle(filterGroup) }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid>
                        <StyledStatusTitle>{tabOptionKeyNames[filterGroup]}</StyledStatusTitle>
                      </Grid>
                      <ToggleButtonGroup
                        exclusive
                        onChange={(_: any, value: string) => onFilterChange(value, filterGroup)}
                      >
                        {filters.map(
                          (filterKey) =>
                            isEnabled(filterKey) && (
                              <Tooltip
                                key={filterKey}
                                title={getHoverDescription(filterGroup, filterKey)}
                                arrow
                              >
                                <BubbleToggleButton
                                  value={filterKey}
                                  selected={isSelected(filterGroup, filterKey)}
                                >
                                  {getFilterLabel(filterGroup, filterKey)}
                                </BubbleToggleButton>
                              </Tooltip>
                            ),
                        )}
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </TabPanel>
      ))}
    </>
  );
}

export default FilterButtonGroup;
