import { Box, Tooltip } from '@mui/material';
import clsx from 'clsx';
import MedWarningBadge from '../../../../iconComponents/medWarningBadge';
import useStyles from './styles';

type MedAlertIconProps = { title: string };

function MedAlertIcon({ title }: Readonly<MedAlertIconProps>) {
  const classes = useStyles();
  return (
    <Tooltip title={title} arrow>
      <Box>
        <MedWarningBadge className={clsx(classes.warningIcon, classes.referralIcon)} />
      </Box>
    </Tooltip>
  );
}

export default MedAlertIcon;
