import { useAuth0 } from '@auth0/auth0-react';
import { Person } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { POPUser } from '../../../auth/apiUtils';
import SignInButton from './signInButton';
import SignOutButton from './signOutButton';

const useStyles = makeStyles((theme) => ({
  layout: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

const getNameInitials = (name: string) => {
  const nameParts = name.split(' ');
  const familyName = nameParts[nameParts.length - 1];

  return nameParts[0].substring(0, 1) + (nameParts.length > 1 ? familyName.substring(0, 1) : '');
};

function UserProfileDialog() {
  const { isAuthenticated, user, error } = useAuth0<POPUser>();

  const classes = useStyles();
  const avatarContent = user?.name ? getNameInitials(user.name) : <Person fontSize="large" />;
  const accessDenied = error?.message === 'Access denied';

  return (
    <div className={classes.layout}>
      <Grid container direction="column" spacing={1}>
        <Grid sx={{ justifyContent: 'center', display: 'flex' }}>
          <Avatar className={classes.purple}>{avatarContent}</Avatar>
        </Grid>

        {user?.name && (
          <Grid display="flex" justifyContent="center">
            {user?.name}
          </Grid>
        )}

        <Grid display="flex" justifyContent="center">
          {isAuthenticated || accessDenied ? <SignOutButton /> : <SignInButton />}
        </Grid>
      </Grid>
    </div>
  );
}

export default UserProfileDialog;
