import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fieldItem: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  logo: {
    height: '100%',
    width: '60%',
  },
}));

export default useStyles;
