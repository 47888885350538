import { Autocomplete, AutocompleteProps, Paper, PaperProps } from '@mui/material';

function CustomPaper(props: PaperProps) {
  return <Paper elevation={8} {...props} />;
}

/** Autocomplete with elevatated dropdown to match Select */
function CustomAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return <Autocomplete PaperComponent={CustomPaper} {...props} />;
}

export default CustomAutocomplete;
