import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    height: '64px',
    fontSize: '0.75rem',
    color: theme.palette.background.lighter,
    backgroundColor: theme.palette.primary.main,
    '& a': {
      color: theme.palette.background.header,
    },
  },
  footerDesktop: {
    height: '64px',
    padding: '20px',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  footerMobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    padding: 0,
  },
  clickableText: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    display: 'inline',
  },
  feedback: {
    padding: theme.spacing(2),
  },
  infoContainerMobile: {
    textAlign: 'left',
    padding: '6px',
  },
  infoItem: {
    display: 'inline-block',
    paddingRight: '10px',
  },
  logoContainer: {
    float: 'right',
    marginRight: '16px',
  },
  logoContainerRTL: {
    float: 'left',
  },
  logoImg: {
    maxHeight: '20px',
    top: '4px',
    position: 'relative',
  },
  poweredBy: {
    fontSize: '0.85rem',
    padding: '12px',
  },
}));

export default useStyles;
