import { useCallback, useEffect, useState } from 'preact/hooks';
import { useContext } from 'react';
import Feature from '../lib/types/feature';
import Risk, { RiskType } from '../lib/types/riskType';
import useFeatureSwitchService from '../services/useFeatureSwitchService';
import { referralActionTypes, StoreContext } from '../store';

// custom hook to get features and flags relaed to feature switches
function useFeatures() {
  const [{ enabledFeatures }, dispatch] = useContext(StoreContext);
  const { getEnabledFeatures, loading: featuresLoading } = useFeatureSwitchService();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const displayDASI = (enabledFeatures ?? []).includes(Feature.DASI);
  const loading = isLoading || featuresLoading;

  useEffect(() => {
    if (enabledFeatures) {
      setIsLoading(false);
      return;
    }

    getEnabledFeatures()
      .then((features) =>
        dispatch({ type: referralActionTypes.SET_ENABLED_FEATURES, payload: features }),
      )
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [dispatch, enabledFeatures, getEnabledFeatures]);

  // Filter for list of ReferralRisks, to determine whether to show DASI or MDASI
  const filterDASI = useCallback(
    ({ riskType }: { riskType: RiskType }) => {
      if (riskType === Risk.MDASI) {
        return !displayDASI;
      }
      // Include DASI if feature switch for DASI is enabled
      return riskType === Risk.DASI ? displayDASI : true;
    },
    [displayDASI],
  );
  return { error, loading, featureList: enabledFeatures ?? [], filterDASI };
}

export default useFeatures;
