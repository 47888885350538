import { useCallback, useContext } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import AlertInfo from '../lib/types/alertInfo';
import { PredictionRecord } from '../lib/types/predictionRecord';
import { ReferralRisk } from '../lib/types/referralRisk';
import { RiskDefinition } from '../lib/types/riskDefinition';
import { RiskType } from '../lib/types/riskType';
import { referralActionTypes, StoreContext } from '../store';

export type AlertDetails = {
  alertId: number;
  alertType: string;
  alertTitle: string;
  orgId: number;
  isDeleted: boolean;
};

export type RiskAlertData = {
  risks: ReferralRisk[];
  alerts: AlertInfo[];
  predictions: PredictionRecord[] | null;
};

function useRiskAlertsService() {
  const { get, put, loading } = useSecureAPI('riskAlerts');
  const [, dispatch] = useContext(StoreContext);

  const getAlertItems = useCallback(() => get<AlertDetails[]>('alerts'), [get]);
  const getRiskItems = useCallback(() => get<RiskDefinition[]>('risks'), [get]);
  const updateAlertItem = useCallback(
    (payload: { isDeleted: boolean }, alertId: number) => put(`alert/${alertId}`, payload),
    [put],
  );
  const updateRiskItem = useCallback(
    (payload: { visible: boolean }, riskType: RiskType) => put(`risk/${riskType}`, payload),
    [put],
  );
  const updateScreen = useCallback(
    (screenType: RiskType, enabled: boolean): Promise<void> =>
      put(`screen/${String(screenType)}`, { enabled }),
    [put],
  );
  const getReferralRiskAlertData = useCallback(
    (gpReferralUuid: string) =>
      get<RiskAlertData>(`gpReferralRiskAlert/${gpReferralUuid}`).then((riskAlerts) => {
        // Update alerts, risks, and predictions in store
        const { alerts, risks, predictions } = riskAlerts;
        dispatch({
          type: referralActionTypes.SET_REFERRAL_ALERTS,
          payload: alerts,
        });
        dispatch({
          type: referralActionTypes.SET_REFERRAL_RISKS,
          payload: risks,
        });
        dispatch({
          type: referralActionTypes.SET_REFERRAL_PREDICTIONS,
          payload: predictions,
        });

        return riskAlerts;
      }),
    [dispatch, get],
  );
  const getReferralScreen = useCallback(
    (referralUuid: string) =>
      get<{ screen: ReferralRisk | null }>(`screen/${referralUuid}`).then((r) => r.screen),
    [get],
  );

  return {
    getAlertItems,
    getRiskItems,
    updateAlertItem,
    updateRiskItem,
    updateScreen,
    getReferralRiskAlertData,
    getReferralScreen,
    loading,
  };
}

export default useRiskAlertsService;
