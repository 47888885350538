import { Publish } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Fade, IconButton, Paper, Popper } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import clsx from 'clsx';
import { useRef, useState } from 'preact/hooks';
import { selectMenuProps } from '../../lib/muiUtils';
import useStyles from './styles';

type ReferralCreateMenuProps = {
  onAddNewClick: () => void;
  onImportClick: () => void;
};

function ReferralCreateMenu({ onAddNewClick, onImportClick }: ReferralCreateMenuProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e: any) => {
    if (anchorRef?.current?.contains(e.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    onImportClick();
    setMenuOpen(false);
  };

  return (
    <Grid container direction="column">
      <Grid>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          className={classes.navbarBtn}
          aria-label="referral-button"
        >
          <Button onClick={onAddNewClick} className={classes.referralBtn} name="nav-new-referral">
            Add new referral
          </Button>
          <Button
            size="small"
            aria-controls={menuOpen ? 'referral-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-label="import referrals"
            aria-haspopup="menu"
            className={clsx(classes.referralBtn, classes.referralArrowBtn)}
            onClick={handleToggle}
            name="nav-import"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={menuOpen} anchorEl={anchorRef.current} transition disablePortal>
          {({ TransitionProps }: any) => (
            <Fade {...TransitionProps}>
              <Paper elevation={1}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id="split-button-menu"
                    MenuProps={selectMenuProps}
                    className={classes.menuItem}
                  >
                    <MenuItem onClick={() => handleMenuItemClick()} name="nav-import-dropdown">
                      <IconButton aria-label="import-menu-item-icon" color="inherit" size="large">
                        <Publish />
                      </IconButton>
                      Import patients from csv
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

export default ReferralCreateMenu;
