import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { useLocation } from 'react-router';

export default function SignInButton() {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () =>
    loginWithRedirect({
      appState: { returnTo: location.pathname },
    });

  return (
    <Button variant="contained" color="primary" onClick={handleLogin}>
      Sign in
    </Button>
  );
}
