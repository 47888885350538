import { TextField } from '@mui/material';
import CustomAutocomplete from '../../../components/customAutocomplete';
import { SurgicalUnit } from '../../../lib/types/surgicalUnit';
import { naturalOrder } from '../../../lib/utils';
import { ColumnId } from '../referralTableColumn';

type SpecialtyFilterProps = Readonly<{
  cellId: ColumnId;
  value: string | undefined;
  onFilterChange: (cellId: ColumnId, value: string | null) => void;
  surgicalUnits: SurgicalUnit[];
}>;

const NO_SPECIALTY = 'No specialty';

function SpecialtyFilter({ cellId, value, onFilterChange, surgicalUnits }: SpecialtyFilterProps) {
  return (
    <CustomAutocomplete
      name={`${cellId}Filter`}
      options={surgicalUnits
        .map((su) => su.surgicalUnitName)
        .sort(naturalOrder)
        .concat([NO_SPECIALTY])}
      onChange={(_, suName) => {
        if (suName === NO_SPECIALTY) {
          onFilterChange(cellId, null);
          return;
        }
        onFilterChange(cellId, suName ?? '');
      }}
      value={value?.startsWith('!') ? undefined : value}
      renderInput={(params) => <TextField {...params} size="small" />}
    />
  );
}

export default SpecialtyFilter;
