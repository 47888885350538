import { SvgIcon } from '@mui/material';

/**
 * @param {SvgIconProps} props
 */
function ContactPrefOptoutIcon(props) {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_2076_152"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2076_152)">
        <path
          d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z"
          fill="inherit"
        />
      </g>
      <path d="M2.5 3L20.5 21" stroke="black" strokeWidth="2" />
      <path d="M3.5 2L21.5 20" stroke="white" strokeWidth="2" />
    </SvgIcon>
  );
}

export default ContactPrefOptoutIcon;
