import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'fixed',
    top: '50vh',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
}));

/** Time before displaying */
const DELAY_TIME = 100;

/** Minimum time to display */
const MIN_TIME = 500;

type Props = {
  loading: boolean;
};

function CentredLoader({ loading }: Props) {
  const [visible, setVisible] = useState(false);
  const startTime = useRef(0);

  const classes = useStyles();

  useEffect(() => {
    if (loading) {
      // Show after delay time
      const timer = setTimeout(() => {
        setVisible(true);
        startTime.current = Date.now();
      }, DELAY_TIME);
      return () => clearTimeout(timer);
    }

    // Loading has stopped
    // If visible for less than min time, delay hiding
    const timeElapsed = Date.now() - startTime.current;
    if (timeElapsed < MIN_TIME) {
      const timer = setTimeout(() => setVisible(false), MIN_TIME - timeElapsed);
      return () => clearTimeout(timer);
    }

    setVisible(false);
    return () => {};
  }, [loading]);

  if (!visible) {
    return null;
  }

  return (
    <div className={classes.loader} name="centredLoader">
      <CircularProgress size={60} />
    </div>
  );
}

export default CentredLoader;
