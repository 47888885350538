import { SvgIcon } from '@mui/material';

/**
 * This icon is not square, so we need to adjust the width and left position to match other MUI icons
 * New width = 29/24 em
 */
const width = '1.208em';
const left = '0.208em';

/**
 * @param {SvgIconProps} props
 */
function CommsUpdatedIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 29 24" style={{ width, left, position: 'relative' }}>
      <mask
        id="mask0_2076_151"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="24"
        height="24"
      >
        <rect y="2" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2076_151)">
        <path
          d="M12 24C10.6167 24 9.31667 23.7375 8.1 23.2125C6.88333 22.6875 5.825 21.975 4.925 21.075C4.025 20.175 3.3125 19.1167 2.7875 17.9C2.2625 16.6833 2 15.3833 2 14C2 12.6167 2.2625 11.3167 2.7875 10.1C3.3125 8.88333 4.025 7.825 4.925 6.925C5.825 6.025 6.88333 5.3125 8.1 4.7875C9.31667 4.2625 10.6167 4 12 4C13.3833 4 14.6833 4.2625 15.9 4.7875C17.1167 5.3125 18.175 6.025 19.075 6.925C19.975 7.825 20.6875 8.88333 21.2125 10.1C21.7375 11.3167 22 12.6167 22 14V15.45C22 16.4333 21.6625 17.2708 20.9875 17.9625C20.3125 18.6542 19.4833 19 18.5 19C17.9167 19 17.3667 18.875 16.85 18.625C16.3333 18.375 15.9 18.0167 15.55 17.55C15.0667 18.0333 14.5208 18.3958 13.9125 18.6375C13.3042 18.8792 12.6667 19 12 19C10.6167 19 9.4375 18.5125 8.4625 17.5375C7.4875 16.5625 7 15.3833 7 14C7 12.6167 7.4875 11.4375 8.4625 10.4625C9.4375 9.4875 10.6167 9 12 9C13.3833 9 14.5625 9.4875 15.5375 10.4625C16.5125 11.4375 17 12.6167 17 14V15.45C17 15.8833 17.1417 16.25 17.425 16.55C17.7083 16.85 18.0667 17 18.5 17C18.9333 17 19.2917 16.85 19.575 16.55C19.8583 16.25 20 15.8833 20 15.45V14C20 11.7667 19.225 9.875 17.675 8.325C16.125 6.775 14.2333 6 12 6C9.76667 6 7.875 6.775 6.325 8.325C4.775 9.875 4 11.7667 4 14C4 16.2333 4.775 18.125 6.325 19.675C7.875 21.225 9.76667 22 12 22H17V24H12ZM12 17C12.8333 17 13.5417 16.7083 14.125 16.125C14.7083 15.5417 15 14.8333 15 14C15 13.1667 14.7083 12.4583 14.125 11.875C13.5417 11.2917 12.8333 11 12 11C11.1667 11 10.4583 11.2917 9.875 11.875C9.29167 12.4583 9 13.1667 9 14C9 14.8333 9.29167 15.5417 9.875 16.125C10.4583 16.7083 11.1667 17 12 17Z"
          fill="inherit"
        />
      </g>
      <circle cx="22" cy="7" r="7" fill="white" />
      <mask
        id="mask1_2076_151"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="1"
        width="12"
        height="12"
      >
        <rect x="16.2734" y="1.27344" width="11.4545" height="11.4545" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_2076_151)">
        <path
          d="M18.8028 9.87557C18.4608 9.49375 18.1923 9.05625 17.9974 8.56307C17.8025 8.06989 17.7051 7.54886 17.7051 7C17.7051 5.80682 18.1227 4.79261 18.9579 3.95739C19.7931 3.12216 20.8074 2.70455 22.0005 2.70455V1.75L24.3869 3.53977L22.0005 5.32955V4.375C21.2767 4.375 20.6582 4.63153 20.1451 5.1446C19.6321 5.65767 19.3755 6.27614 19.3755 7C19.3755 7.36591 19.4451 7.70795 19.5843 8.02614C19.7235 8.34432 19.9125 8.62273 20.1511 8.86136L18.8028 9.87557ZM22.0005 12.25L19.6142 10.4602L22.0005 8.67045V9.625C22.7244 9.625 23.3429 9.36847 23.8559 8.8554C24.369 8.34233 24.6255 7.72386 24.6255 7C24.6255 6.63409 24.5559 6.29205 24.4167 5.97386C24.2775 5.65568 24.0886 5.37727 23.85 5.13864L25.1983 4.12443C25.5403 4.50625 25.8088 4.94375 26.0037 5.43693C26.1985 5.93011 26.296 6.45114 26.296 7C26.296 8.19318 25.8784 9.20739 25.0431 10.0426C24.2079 10.8778 23.1937 11.2955 22.0005 11.2955V12.25Z"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
}

export default CommsUpdatedIcon;
