import { Tooltip } from '@mui/material';
import ContactPrefNotificationsOffIcon from '../../../../iconComponents/contactPrefNotificationsOffIcon';
import ContactPrefOptoutIcon from '../../../../iconComponents/contactPrefOptoutIcon';
import ContactPrefPhoneIcon from '../../../../iconComponents/contactPrefPhoneIcon';
import useStyles from './styles';

type ContactPrefIconProps = {
  sendReminders: boolean;
  sendCorrespondence: boolean;
  phoneOnly: boolean;
};

function ContactPrefIcon({
  sendReminders,
  sendCorrespondence,
  phoneOnly,
}: Readonly<ContactPrefIconProps>) {
  const classes = useStyles();

  const phoneIcon = <ContactPrefPhoneIcon alt="Contact Pref" className={classes.referralIcon} />;
  const notificationsIcon = (
    <ContactPrefNotificationsOffIcon alt="Contact Pref" className={classes.referralIcon} />
  );
  const optoutIcon = <ContactPrefOptoutIcon alt="Contact Pref" className={classes.referralIcon} />;

  let icon = null;
  let warningText = '';

  if (phoneOnly) {
    warningText = 'Use phone, patient has indicated they do not have an email address';
    icon = phoneIcon;
  } else if (!sendCorrespondence) {
    warningText = 'Patient has opted out of receiving emails';
    icon = optoutIcon;
  } else if (!sendReminders) {
    warningText = 'Patient has opted out of receiving reminders';
    icon = notificationsIcon;
  } else {
    return null;
  }

  return (
    <Tooltip title={warningText} arrow>
      <div>{icon}</div>
    </Tooltip>
  );
}

export default ContactPrefIcon;
