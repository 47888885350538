import { SvgIcon } from '@mui/material';

/**
 * @param {SvgIconProps} props
 */
function MedWarningBadge(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 49 49">
      <circle cx="24.5" cy="24.5" r="23" fill="#FF0000" stroke="white" strokeWidth="3" />
      <mask
        id="mask0_2083_47"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="35"
        height="35"
      >
        <rect x="7" y="7" width="35" height="35" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2083_47)">
        <path
          d="M22.3138 32.5208H26.6888V28.875H30.3346V24.5H26.6888V20.8542H22.3138V24.5H18.668V28.875H22.3138V32.5208ZM17.2096 37.625C16.4076 37.625 15.7209 37.3394 15.1497 36.7682C14.5786 36.197 14.293 35.5104 14.293 34.7083V18.6667C14.293 17.8646 14.5786 17.178 15.1497 16.6068C15.7209 16.0356 16.4076 15.75 17.2096 15.75H31.793C32.5951 15.75 33.2817 16.0356 33.8529 16.6068C34.424 17.178 34.7096 17.8646 34.7096 18.6667V34.7083C34.7096 35.5104 34.424 36.197 33.8529 36.7682C33.2817 37.3394 32.5951 37.625 31.793 37.625H17.2096ZM15.7513 14.2917V11.375H33.2513V14.2917H15.7513Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default MedWarningBadge;
