import { Avatar, Badge, Box, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/styles';
import { useEffect, useState } from 'preact/hooks';
import { PatientEmailStateType } from '../../../../lib/types/patientEmailState';
import { PatientDetails } from '../../../../lib/types/referralListItem';
import { ReferralStatus } from '../../../../lib/types/referralStatus';
import useHQReviewService from '../../../../services/useHQReviewService';
import CommStatusIcon from './commStatusIcon';
import ContactInfoAlertIcon from './contactInfoAlertIcon';
import ContactPrefIcon from './contactPrefIcon';
import LocationWarningIcon from './locationWarningIcon';
import MedAlertIcon from './medAlertIcon';

type Props = {
  gpReferralUuid: string;
  currentState: ReferralStatus;
  medWarningsPresent: boolean;
  locationWarningsPresent: boolean;
  surgeryLocationName: string | null;
  patientEmailState: PatientEmailStateType;
  patient: PatientDetails;
  sendReminders: boolean;
};

const StyledAvatar = styled(Avatar)({
  height: 62,
  width: 62,
});

const AvatarContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  py: 1,
});

function ReferralAvatar({
  gpReferralUuid,
  currentState,
  medWarningsPresent,
  locationWarningsPresent,
  surgeryLocationName,
  patientEmailState,
  patient,
  sendReminders,
}: Props) {
  const { getPatientThumbnails } = useHQReviewService();
  const [loading, setLoading] = useState(false);
  const [frontThumbnail, setFrontThumbnail] = useState<string | undefined>(undefined);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (patient.profileImage) {
      setLoading(true);
      getPatientThumbnails(gpReferralUuid, { signal })
        .then((res) => {
          if (res.hasPhotos) {
            setFrontThumbnail(res.photos.frontThumbnail ?? undefined);
          }
        })
        .finally(() => setLoading(false));
    }

    return () => controller.abort();
  }, [getPatientThumbnails, gpReferralUuid, patient.profileImage]);

  const showOptOutWarning = !sendReminders || !patient.sendCorrespondence || patient.phoneOnly;
  const showCommStatusIcon =
    !patient.phoneOnly &&
    [ReferralStatus.HQSent, ReferralStatus.DetailsRequested].includes(currentState);

  /**
   * There are 3 major alerts to be shown as a badge.
   * 1) No email provided
   * 2) Medication alert
   * 3) Location warning
   *
   * Email/Medication are not expected to be true at the same time, and are relevant at different stages of the workflow
   * 1) No email is before the patient has filled the HQ, and for the 'waitlist' referral list tab
   * 2) Medication is once the patient has filled the HQ, and for all the other referral list tabs
   *
   * Medication has priority over location warning
   */
  const getBadgeContent = () => {
    // Med must be in preference to email warning
    // Because med warning more important AND if there is a med warning, then already solved comms issues
    if (medWarningsPresent) {
      return <MedAlertIcon title="Medication warnings" />;
    }

    if (locationWarningsPresent) {
      return <LocationWarningIcon surgeryLocationName={surgeryLocationName} />;
    }

    if (!patient.email) {
      return <ContactInfoAlertIcon title="Missing email address" />;
    }

    return null;
  };

  const fullName = `${patient.lastName}, ${patient.firstName}`;

  return (
    <AvatarContainer>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={getBadgeContent()}
      >
        {loading ? (
          <Skeleton variant="circular">
            <StyledAvatar alt={fullName} />
          </Skeleton>
        ) : (
          <StyledAvatar src={frontThumbnail} alt={fullName} />
        )}
      </Badge>
      <Typography sx={{ pl: 1, fontSize: 16 }}>{fullName}</Typography>
      <Grid
        container
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {/* icon for opt-out info */}
        {showOptOutWarning && (
          <ContactPrefIcon
            sendReminders={sendReminders}
            sendCorrespondence={patient.sendCorrespondence}
            phoneOnly={patient.phoneOnly}
          />
        )}

        {/* icon for status of communication with patient */}
        {showCommStatusIcon && <CommStatusIcon patientEmailState={patientEmailState} />}
      </Grid>
    </AvatarContainer>
  );
}

export default ReferralAvatar;
