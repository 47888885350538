import { Alert, AlertColor, AlertTitle, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ComponentChildren } from 'preact';

type AlertMultilineProps = {
  title: string;
  severity: AlertColor;
  standalone?: boolean;
  children?: ComponentChildren;
};

export default function AlertMultiline({
  title,
  severity,
  standalone = false,
  children,
}: AlertMultilineProps) {
  const fullWidth = !standalone;
  const variant = standalone ? 'filled' : 'standard';

  return (
    <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Alert
        variant={variant}
        severity={severity}
        sx={{ margin: '0.5rem', width: fullWidth ? '100%' : undefined }}
      >
        <AlertTitle sx={{ fontWeight: 800, top: 2, position: 'relative' }}>{title}</AlertTitle>
        <Typography variant="body1">{children}</Typography>
      </Alert>
    </Grid>
  );
}
