import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { ColumnId } from '../referralTableColumn';

type TextSearchFilterProps = {
  cellId: ColumnId;
  value: string | undefined;
  onFilterChange: (cellId: ColumnId, value?: string) => void;
};

function TextSearch({ cellId, value, onFilterChange }: TextSearchFilterProps) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    // Handle external clear
    if (!value) {
      setLocalValue(value);
    }
  }, [value]);

  const handleClearFilter = () => {
    setLocalValue('');
    onFilterChange(cellId);
  };

  const onChange = useCallback(
    (e: Event) => {
      const newVal = (e.target as HTMLInputElement).value;
      setLocalValue(newVal);
      onFilterChange(cellId, newVal || undefined);
    },
    [cellId, onFilterChange],
  );

  return (
    <TextField
      name={`${cellId}Filter`}
      fullWidth
      onChange={onChange}
      value={localValue}
      size="small"
      slotProps={{
        input: {
          endAdornment: !value ? undefined : (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear filter option"
                onClick={handleClearFilter}
                style={{ padding: 0 }}
                size="large"
              >
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}

export default TextSearch;
