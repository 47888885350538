import { PartialRecord } from '../../lib/tsUtils';
import { ReferralStatus } from '../../lib/types/referralStatus';
import RiskLevel, { RiskLevelType } from '../../lib/types/riskLevel';
import Risk, { RiskType } from '../../lib/types/riskType';

export type DateRangeValues = {
  startDate?: Date;
  endDate?: Date;
};

export type ScreenType = typeof RiskLevel.High;

export interface ColumnFilters {
  name: string;
  ur: string;
  surgicalUnitName: string;
  procedureName: string;
  state: ReferralStatus[];
  asaScore: number;
  prehab: ScreenType;
  rehab: ScreenType;
  postOp: ScreenType;
  followUp: ScreenType;
  endoscopySuitable: ScreenType;
  privateSuitable: ScreenType;
  dayCase: ScreenType;
  complexityMetsRisk: RiskLevelType;
  riskLevel: RiskLevelType;
  triageCategory: number;
  surgeryDate: DateRangeValues;
  waitlistDate: Date;
}

export type ColumnId = keyof ColumnFilters;

/** Map of risk type to associated list column ID */
export const riskColumnMap: PartialRecord<RiskType, ColumnId> = {
  [Risk.ASA]: 'asaScore',
  [Risk.ComplexityMetsRisk]: 'complexityMetsRisk',
  [Risk.Endoscopy]: 'endoscopySuitable',
  [Risk.PostOp]: 'postOp',
  [Risk.Prehab]: 'prehab',
  [Risk.Rehab]: 'rehab',
  [Risk.FollowUp]: 'followUp',
  [Risk.PrivateSuitable]: 'privateSuitable',
  [Risk.DayCase]: 'dayCase',
};

type HeaderRow = { id: ColumnId; label: string; width?: number };
export const tableHeaders: HeaderRow[] = [
  { id: 'name', label: 'Name' },
  { id: 'ur', label: 'UR', width: 150 },
  { id: 'surgicalUnitName', label: 'Specialty' },
  { id: 'procedureName', label: 'Procedure' },
  { id: 'state', label: 'Status', width: 250 },
  { id: 'surgeryDate', label: 'Date of surgery', width: 250 },
  { id: 'triageCategory', label: 'Category', width: 50 },
  { id: 'followUp', label: 'Waitlist check-in', width: 150 },
  { id: 'complexityMetsRisk', label: 'Screen', width: 150 },
  { id: 'postOp', label: 'Post-op', width: 150 },
  { id: 'asaScore', label: 'ASA', width: 50 },
  { id: 'endoscopySuitable', label: 'PAC Screen', width: 150 },
  { id: 'privateSuitable', label: 'St. Vincents', width: 150 },
  { id: 'dayCase', label: 'Broadmeadows', width: 150 },
  { id: 'prehab', label: 'Prehabilitation', width: 150 },
  { id: 'rehab', label: 'Rehabilitation', width: 150 },
  { id: 'riskLevel', label: 'Risk', width: 150 },
  { id: 'waitlistDate', label: 'Date waitlisted', width: 200 },
];
