import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DefaultCollection } from '../../../lib/hqCollection';
import { IncompleteQuestionnaireKey } from '../../../lib/types/DTO/incompleteQuestionnaireKey';
import { formatDate, formatText } from '../../../lib/utils';
import useReferralService from '../../../services/useReferralService';

type Props = {
  referralUuid: string;
};

function ReferralQuestionnaires({ referralUuid }: Props) {
  const [keys, setKeys] = useState<IncompleteQuestionnaireKey[]>([]);
  const { getIncompleteQuestionnaires } = useReferralService();

  useEffect(() => {
    getIncompleteQuestionnaires(referralUuid).then((incompleteKeys) => {
      setKeys(
        incompleteKeys.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        ),
      );
    });
  }, [getIncompleteQuestionnaires, referralUuid]);

  if (!keys.length) {
    return null;
  }

  const formatCollectionName = (collectionName: string) => {
    if (collectionName === DefaultCollection) {
      return 'Health questionnaire';
    }
    return formatText(collectionName);
  };

  return (
    <Box>
      <Typography variant="h4">Incomplete questionnaires</Typography>

      <Grid container>
        <Grid size={3}>
          <b>Sent at</b>
        </Grid>
        <Grid size={3}>
          <b>Type</b>
        </Grid>
        <Grid size={6} />

        {keys.map(({ createdAt, collectionName, validKey }) => (
          <>
            <Grid size={3}>{formatDate(createdAt)}</Grid>
            <Grid size={3}>{formatCollectionName(collectionName)}</Grid>
            <Grid size={6}>
              <Link
                activeClassName="active"
                to={`/healthquestionnaire/${validKey}`}
                color="textSecondary"
                underline="always"
                name="questionnaireLink"
              >
                Click to fill in questionnaire
              </Link>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

export default ReferralQuestionnaires;
