export enum ReferralStatus {
  WaitingList = 'waitingList',
  DetailsRequested = 'detailsRequested',
  HQSent = 'hqSent',
  HQReceived = 'hqReceived',
  AppointmentBooked = 'appointmentBooked',
  ReadyForSurgery = 'readyForSurgery',
  ReadyForBowelPrep = 'readyForBowelPrep',
  BookedForSurgery = 'bookedForSurgery',
  PostOpHQSent = 'postOpHqSent',
  PostOpHQReceived = 'postOpHqReceived',
  Cancelled = 'cancelled',
}

export const referralStatusNames: Record<ReferralStatus, string> = {
  waitingList: 'Waiting list',
  detailsRequested: 'Details requested',
  hqSent: 'HQ sent',
  hqReceived: 'HQ received',
  appointmentBooked: 'Sent to PAC',
  readyForSurgery: 'Ready (no date)',
  bookedForSurgery: 'Ready (with date)',
  readyForBowelPrep: 'Ready For bowel prep',
  postOpHqSent: 'Post-op HQ sent',
  postOpHqReceived: 'Post-op HQ received',
  cancelled: 'Cancelled',
} as const;
