import { Announcement, Assignment, DateRange, Speed, ViewList } from '@mui/icons-material';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import useStyles from '../settingDialogStyle';
import SettingsMenuItem from './settingsMenuItem';

interface SettingsMenuProps {
  onMenuItemSelect: () => void;
  features: {
    prediction: boolean;
  };
}

function SettingsMenu({ onMenuItemSelect, features }: SettingsMenuProps): JSX.Element {
  const navigate = useNavigate();
  const classes = useStyles();

  const makeLinkHandler = (linkRoute: string) => () => {
    onMenuItemSelect();
    return navigate(linkRoute);
  };

  const toManageUsers = makeLinkHandler('/users/list');
  const toManageReferral = makeLinkHandler('/manage/referral');
  const toManageNotifications = makeLinkHandler('/manage/notifications');
  const toManageReminders = makeLinkHandler('/manage/reminders');
  const toManageRiskAlerts = makeLinkHandler('/manage/alerts-risks');
  const toManagePredictions = makeLinkHandler('/manage/predictions');
  const toManageReferralList = makeLinkHandler('/manage/referralList');
  const toManagePostOp = makeLinkHandler('/manage/hq-settings');
  const toManageAdvice = makeLinkHandler('/manage/advice');

  return (
    <div className={classes.menuItem}>
      <SettingsMenuItem title="Users" icon={<PeopleIcon />} onClick={toManageUsers} />

      <SettingsMenuItem
        title="Notifications"
        icon={<NotificationsActiveIcon />}
        onClick={toManageNotifications}
      />

      <SettingsMenuItem
        title="Hospital configuration"
        icon={<LocalHospitalIcon />}
        onClick={toManageReferral}
      />

      <SettingsMenuItem title="Alerts/Risks" icon={<WarningIcon />} onClick={toManageRiskAlerts} />

      <SettingsMenuItem title="Reminders" icon={<DateRange />} onClick={toManageReminders} />

      {features.prediction && (
        <SettingsMenuItem title="Predictions" icon={<Speed />} onClick={toManagePredictions} />
      )}

      <SettingsMenuItem title="Referral list" icon={<ViewList />} onClick={toManageReferralList} />

      <SettingsMenuItem title="Questionnaires" icon={<Assignment />} onClick={toManagePostOp} />

      <SettingsMenuItem title="Advice" icon={<Announcement />} onClick={toManageAdvice} />
    </div>
  );
}

export default SettingsMenu;
