import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  referralListCountStyle: {
    height: '1rem !important',
    width: '1rem !important',
    marginLeft: '0.5rem',
  },
}));

type Props = {
  paginationInfo: {
    from: number;
    to: number;
    count: number;
  };
  showSpinner: boolean;
};

function ReferralListCount({ paginationInfo, showSpinner }: Props) {
  const classes = useStyles();

  const { from, to, count } = paginationInfo;
  return (
    <>
      {from} - {to} of{' '}
      {showSpinner ? (
        <CircularProgress classes={{ root: classes.referralListCountStyle }} />
      ) : (
        count
      )}
    </>
  );
}

export default ReferralListCount;
