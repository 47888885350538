import { Typography } from '@mui/material';

type Props = {
  surgicalUnitName: string | null;
  procedureName: string | null;
};

function SurgicalUnitCell({ surgicalUnitName, procedureName }: Props) {
  return (
    <>
      <Typography variant="h4" color="secondary">
        {surgicalUnitName ?? 'No specialty'}
      </Typography>
      {procedureName}
    </>
  );
}

export default SurgicalUnitCell;
