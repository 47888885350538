import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { SuitabilityFilterText } from '../../../lib/riskDisplayUtils';
import { PartialRecord } from '../../../lib/tsUtils';
import { ReferralRisk } from '../../../lib/types/referralRisk';
import RiskLevel, { RiskLevelType } from '../../../lib/types/riskLevel';
import { formatText } from '../../../lib/utils';
import RiskScores from './badgeContent/riskScores';
import RiskBadge from './riskBadge';

type Props = {
  risk: ReferralRisk;
  textMappingOverride?: PartialRecord<RiskLevelType, string>;
};

function SuitabilityScreenBadge({ risk, textMappingOverride }: Props) {
  const { riskLevel, assessment, secondaryScores } = risk;

  const popoverContent = useMemo(() => {
    if (riskLevel !== RiskLevel.High) {
      return null;
    }

    return (
      <>
        <Typography variant="h4">{formatText(assessment)}</Typography>
        <RiskScores riskScores={secondaryScores} />
      </>
    );
  }, [assessment, riskLevel, secondaryScores]);

  // Don't display badge if there are no recommendations
  if (!risk.secondaryScores) {
    return null;
  }

  return (
    <RiskBadge
      riskLevel={riskLevel}
      popoverContent={popoverContent}
      textMapping={textMappingOverride ?? SuitabilityFilterText}
    />
  );
}

export default SuitabilityScreenBadge;
