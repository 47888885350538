import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'fixed',
    top: '50vh',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
}));

type Props = {
  content: string | JSX.Element | JSX.Element[] | null;
  loading?: boolean;
};

/**
 * Page with large centred text, and optional loading spinner
 */
function LoaderTextPage({ content, loading }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.loader} name="centredLoader">
      {loading && (
        <div style={{ textAlign: 'center', marginBottom: 8 }}>
          <CircularProgress size={60} />
        </div>
      )}
      <Typography variant="h2" color="primary">
        {content}
      </Typography>
    </div>
  );
}

export default LoaderTextPage;
